










import { Component, Vue } from 'vue-property-decorator';
import TopMenu from './components/TopMenu.vue';
import Splash from './components/Splash.vue';
import SectionPreview from './components/SectionPreview.vue';

@Component({
    components: {
        TopMenu,
        Splash,
        SectionPreview,
    },
})
export default class App extends Vue { }
