import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Home Page - vkorotenko',
                metaTags: [
                    {
                        name: 'description',
                        content: 'The home page of our example app.',
                    },
                    {
                        property: 'og:description',
                        content: 'The home page of our example app.',
                    },
                ],
            },
        },
        {
            path: '/about',
            name: 'about',
            component: () => import( /*webpackChunkName: "about" */ './views/About.vue'),
            meta: {
                title: 'About Page - vkorotenko',
                metaTags: [
                    {
                        name: 'description',
                        content: 'The home page of our example app.',
                    },
                    {
                        property: 'og:description',
                        content: 'The home page of our example app.',
                    },
                ],
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        },

    ],
});
