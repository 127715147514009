










import { Component, Prop, Vue } from 'vue-property-decorator';
import Footer from './Footer.vue';
@Component({
  components: {
    Footer,
  }
})
export default class SectionPreview extends Vue {}
