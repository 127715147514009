































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TopMenu extends Vue {}

const ready = (callback: { (): void; (): void }) => {
  if (document.readyState !== 'loading') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

ready(() => {
  window.addEventListener('scroll', (e: Event) => {
    const top = document.documentElement.scrollTop;
    const pos = (top / 3).toFixed(2);
    const splash = document.querySelector('.splash') as HTMLDivElement;
    if (splash !== null) {
      splash.style.backgroundPosition = '0px -' + pos + 'px';
    }

    const homeNavbar = document.querySelector(
      '#home > .navbar',
    ) as HTMLDivElement;
    if (top > 50) {
      homeNavbar.classList.remove('is-transparent');
    } else {
      homeNavbar.classList.add('is-transparent');
    }
  });
  const abar = document.querySelector('a[href="/#"]') as HTMLAnchorElement;
  abar.addEventListener('click', (e: any) => {
    e.preventDefault();
  });
});
